const appBar = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      color: "transparent",
    },
  },
};

export default appBar;
