import React, { useState } from 'react';
import Tooltip from 'rc-tooltip';
import ButtonLink from 'components/global/buttonLink';
import PlanModal from './planModal';
import default_data from "values/default";
//https://www.npmjs.com/package/rc-tooltip

const PricingCard = ({ planName, price, period, referralFee, features, activePlan, activePrice, activeReferralFee, activePlanStatus }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (planName) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const Checkmark = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4.5 12.75L10.5 18.75L19.5 5.25" stroke="#434CA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  };

  const Cross = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="opacity-60" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 18L18 6M6 6L18 18" stroke="#434CA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  };

  const tooltips = {
    1: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Referral Fee</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">The percentage of the Gross Commission that will be paid by the Receiving Agent to BTRN.</p>
      </div>
    ),
    2: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Priority for buyer referrals</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">You'll be given priority to accept buyer referrals over agents on the basic and free plans.</p>
      </div>
    ),
    3: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Priority for Seller Referrals</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">You'll be given priority to accept seller referrals over agents on the basic and free plans.</p>
      </div>
    ),
    4: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Home & Money profile</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">Stand out with a personalized, conversion-optimized Home & Money profile that showcases your brand.</p>
      </div>
    ),
    5: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Targeted Lead Generation Campaigns</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">We drive high-intent paid traffic directly to your Home & Money agent profile.</p>
      </div>
    ),
    6: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Direct-to-Agent lead flow</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">Conversation-ready referrals are sent directly from our lead funnels to your portal, ready for immediate follow-up.</p>
      </div>
    ),
    7: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Home & Money testimonials</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">Highlight your expertise by featuring client testimonials directly on your Home & Money agent profile.</p>
      </div>
    ),
    8: (
      <div className="flex gap-[12px] flex-col">
        <h4 className="text-[#444] text-[16px] not-italic font-semibold leading-[120%]">Custom reporting dashboard</h4>
        <p className="text-[#444] text-[14px] not-italic font-normal leading-[150%]">Track your performance with a data-driven reporting dashboard that offers key insights from Google Analytics.</p>
      </div>
    ),
  };

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 768);
  });

  if (planName === default_data.subscription_plans.free_profile) {
    return (
      <div className="p-6 bg-white w-full sm:max-w-[450px] lg:max-w-screen-xl rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.05)] border border-[#bec2d1] justify-start items-start gap-16 inline-flex">
        <div className="inline-flex flex-col items-start justify-start w-full gap-6 grow shrink basis-0">
          <div className="flex flex-col items-start self-stretch justify-start gap-3">
            <div className="inline-flex items-center self-stretch justify-start gap-4">
              <div className="grow shrink basis-0 text-[#2a2f64] text-base font-semibold leading-tight">{planName}</div>
              {activePlan === planName && (
                <div className="PopularTag w-max flex px-[6px] py-[8px] items-center gap-[4px] rounded-[100px] bg-[#6471ED]">
                  <div className="Text text-[#FFF] text-center text-[12px] not-italic font-bold leading-[18px] uppercase">{activePlanStatus}</div>
                </div>
              )}
            </div>
            <div className="flex flex-col self-stretch justify-start gap-3 lg:items-center lg:flex-row">
              <div className="flex flex-col items-start justify-start gap-3 lg:items-center grow shrink basis-0 lg:flex-row">
                <div className="flex items-end justify-start gap-1">
                  <div className="text-[#2a2f64] text-[25px] font-medium leading-[30px]">{price}</div>
                  <div className="flex items-start justify-start pb-1">
                    <div className="text-[#2a2f64] text-base font-normal leading-normal">/month</div>
                  </div>
                </div>
                <div className="flex items-start justify-start h-6 gap-2 grow shrink basis-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 4.5V19.5M19.5 12H4.5" stroke="#434CA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <Tooltip
                    overlay={tooltips[1]}
                    placement="topLeft"
                    trigger={isMobile ? ['click'] : ['hover']}
                    showArrow={false}
                  >
                    <div className="ReferralFee border-b border-dashed border-[#667085] text-[#2A2F64] text-[16px] not-italic font-semibold leading-[150%]">{referralFee} Referral Fee</div>
                  </Tooltip>
                </div>
              </div>
              <ButtonLink
                  text={activePlan === planName ? 'Active' : 'Select'}
                  href={activePlan === planName ? null : '#'}
                  disabled={activePlan === planName}
                  className={`flex flex-row text-center text-[14px] not-italic font-semibold leading-[14px] px-[24px] py-[15px] justify-center items-center self-stretch rounded-[8px] 
                    ${activePlan === planName ? 'bg-[#D0D5DD] text-[#667085]' : 'bg-[#434CA0] hover:bg-[#373E82]'} 
                    text-[#FFF] [box-shadow:0px_1px_2px_0px_rgba(0,_0,_0,_0.15)]`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (activePlan === planName) {
                      return;
                    }
                    openModal(planName);
                  }}
                />
            </div>
          </div>
        </div>
        <PlanModal
          isOpen={isModalOpen}
          onClose={closeModal}
          planName={planName}
          activePlan={activePlan}
          referralFee={referralFee}
          price={price}
          activePrice={activePrice}
          activeReferralFee={activeReferralFee}
          isCancelingPlan={planName === default_data.subscription_plans.free_profile}
        />
      </div>
    );
  }


  return (
    <div className="HostingCard w-full max-w-[450px] sm:min-w-[360px] lg:min-w-[250px] 2xl:min-w-[360px] flex p-[20px] flex-col items-start gap-[32px] flex-[1_0_0] rounded-[12px] border-[1px] border-[solid] border-[#BEC2D1] bg-[#FFF] [box-shadow:0px_2px_2px_0px_rgba(0,_0,_0,_0.05)] relative">
      <div className="flex flex-col items-start gap-[24px] flex-[1_0_0] self-stretch Frame14178">
        <div className="HeadingAndPrice flex flex-col items-start gap-[24px] self-stretch">
          <div className="flex h-[24px] items-center gap-[16px] self-stretch HeadingAndBadge justify-between">
            <div className="Heading text-[#2A2F64] text-[16px] not-italic font-semibold leading-[120%]">{planName}</div>
            {activePlan === planName && (
              <div className="PopularTag w-max flex px-[6px] py-[8px] items-center gap-[4px] rounded-[100px] bg-[#6471ED]">
                <div className="Text text-[#FFF] text-center text-[12px] not-italic font-bold leading-[18px] uppercase">{activePlanStatus}</div>
              </div>
            )}
          </div>
          <div className="Frame14180 flex flex-col items-start gap-[16px] self-stretch">
            <div className="flex items-end gap-[4px] self-stretch Price">
              <div className="Price text-[#2A2F64] text-[34px] not-italic font-semibold leading-[100%] tracking-[-1.7px]">{price}</div>
              <div className="flex pb-[4px] items-start PeriodWrap">
                <div className="Period text-[#2A2F64] text-[16px] not-italic font-normal leading-[150%]">{period}</div>
              </div>
            </div>
            <div className="flex items-center gap-[8px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 4.5V19.5M19.5 12H4.5" stroke="#434CA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            <Tooltip
              overlay={tooltips[1]}
              placement="topLeft"
              trigger={isMobile ? ['click'] : ['hover']}
              showArrow={false}
            >
              <div className="ReferralFee border-b border-dashed border-[#667085] text-[#2A2F64] text-[16px] not-italic font-semibold leading-[150%]">{referralFee} Referral Fee</div>
              </Tooltip>
            </div>
            <ButtonLink
              text={activePlan === planName ? 'Active' : 'Select'}
              href={activePlan === planName ? null : '#'}
              disabled={activePlan === planName}
              className={`flex flex-row text-center text-[14px] not-italic font-semibold leading-[14px] px-[24px] py-[15px] justify-center items-center self-stretch rounded-[8px] 
                ${activePlan === planName ? 'bg-[#D0D5DD] text-[#667085]' : 'bg-[#434CA0] hover:bg-[#373E82]'} 
                text-[#FFF] [box-shadow:0px_1px_2px_0px_rgba(0,_0,_0,_0.15)]`}
                onClick={(e) => {
                  e.preventDefault();
                  if (activePlan === planName) {
                    return;
                  }
                  openModal(planName);
                }}
            />
          </div>
        </div>
        <div className="Features flex flex-col items-start gap-[8px] self-stretch">
        {features.map((feature, index) => (
            feature.tooltip ? (
              <Tooltip
                key={index}
                overlay={tooltips[feature.tooltip]}
                placement="topLeft"
                trigger={isMobile ? ['click'] : ['hover']}
                showArrow={false}
              >
                <div
                  className={`FeatureItem flex items-center gap-[8px] ${feature.enabled ? 'text-[#2A2F64]' : 'text-[#CCC]'} text-[14px]`}
                >
                  <span>{feature.enabled ? <Checkmark /> : <Cross />}</span>
                  <span className="border-b border-dashed border-[#667085]">{feature.text}</span>
                </div>
              </Tooltip>
            ) : (
              <div
                key={index}
                className={`FeatureItem flex items-center gap-[8px] ${feature.enabled ? 'text-[#2A2F64]' : 'text-[#CCC]'} text-[14px]`}
              >
                <span>{feature.enabled ? <Checkmark /> : <Cross />}</span>
                <span>{feature.text}</span>
              </div>
            )
          ))}
        </div>
      </div>
      <PlanModal
        isOpen={isModalOpen}
        onClose={closeModal}
        planName={planName}
        activePlan={activePlan}
        referralFee={referralFee}
        price={price}
        activePrice={activePrice}
        activeReferralFee={activeReferralFee}
        isCancelingPlan={planName === default_data.subscription_plans.free_profile}
      />
    </div>
  );
};

export default PricingCard;
