// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Duplicate() {
    return (
        <DashboardLayout>
        <DashboardNavbar />
            <Grid container  direction="column" height="80vh">
                <Grid item xs>
                    <MDBox >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <MDTypography align="left" variant="h2" pt={5} pb={4} color="soliddark"><b>Please report the duplicate transaction to us</b></MDTypography>
                                <MDBox bgColor="white" p={3} sx={{ borderRadius: "12px" }}>
                                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                                        <MDTypography variant="h4" color="soliddark">Email to : <a style={{color: '#283567'}} href="mailto:escrow@myagentfinder.com">escrow@myagentfinder.com</a></MDTypography>
                                    </MDBox>

                                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                                        <MDTypography variant="h4" color="soliddark">Subject: Duplicate transaction </MDTypography>
                                    </MDBox>
                                    <MDTypography variant="subtitle2" color="soliddark" mb={2}>(Please provide the following details for the transaction.)</MDTypography>
                                    <MDTypography variant="subtitle2" color="soliddark">Client Name: </MDTypography>
                                    <MDTypography variant="subtitle2" color="soliddark">Client Phone: </MDTypography>
                                    <MDTypography variant="subtitle2" color="soliddark">Client email address: </MDTypography>
                                    <MDTypography variant="subtitle2" color="soliddark">Transaction address: </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
                <Grid item style={{ alignSelf: 'flex-start' }}>
                    <Footer />
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default Duplicate;
