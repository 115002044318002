import Grid from "@mui/material/Grid";
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDDropzone from "components/MDDropzone";
import Cookies from 'js-cookie';

function Image({ logo, image, influencer }) {
    const [uploadError, setUploadError] = useState("");

    return (
        <MDBox>
            <Grid justifyContent="center">
                {influencer == "cost_per_lead" ? <></> :
                    <MDBox mb={3}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <Card>
                                    <MDBox p={2}>
                                        <MDTypography align="left" variant="h3">Logo</MDTypography>
                                    </MDBox>
                                    <MDBox pt={2} px={2}>
                                        <Grid container spacing={3} pb={3}>
                                            <Grid item xs={12} lg={6}>
                                                {logo ?
                                                    <MDBox
                                                        component="img"
                                                        src={logo}
                                                        alt="Logo"
                                                        borderRadius="lg"
                                                        width="100%"
                                                        sx={{ 'max-height': "250px", 'min-height': "150px" }}
                                                    /> :
                                                    <MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
                                                        <MDTypography variant="h6" color="text">No Image</MDTypography>
                                                    </MDBox>
                                                }
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <MDDropzone options={{
                                                    addRemoveLinks: true, paramName: "logo", params: { "name": "test" }, headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' },
                                                    init: function () {
                                                        this.on("success", function (file) {
                                                            window.location.reload();
                                                        });
                                                    }
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>}


                <MDBox mb={3}>
                    <Grid container spacing={3} mb={4} justifyContent="center">
                        <Grid item xs={12} lg={8}>
                            <Card>
                                <MDBox p={2}>
                                    <MDTypography align="left" variant="h3">Profile Picture</MDTypography>
                                    <MDTypography variant="caption">Upload a high-quality JPG or PNG with a 2:3 width-to-height ratio, at least 600px wide, and under 500kb for best results.</MDTypography>
                                </MDBox>
                                <MDBox pt={2} px={2}>
                                    <Grid container spacing={3} pb={3}>
                                        {uploadError && (
                                            <Grid item xs={12}>
                                                <MDTypography 
                                                    color="error" 
                                                    variant="caption" 
                                                    fontWeight="medium"
                                                >
                                                    {uploadError}
                                                </MDTypography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} lg={6}>
                                            {image ?
                                                <MDBox
                                                    component="img"
                                                    src={image}
                                                    alt="Logo"
                                                    borderRadius="lg"
                                                    width="100%"
                                                    sx={{ 'max-height': "250px", 'min-height': "150px" }}
                                                /> :
                                                <MDBox color="black" bgColor="blackout" variant="gradient" coloredShadow="none" borderRadius="lg" opacity={1} p={2}>
                                                    <MDTypography variant="h6" color="text">No Image</MDTypography>
                                                </MDBox>
                                            }
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MDDropzone options={{
                                                addRemoveLinks: true,
                                                paramName: "profile_image",
                                                params: { "name": "test" },
                                                headers: { 'Authorization': 'Bearer ' + Cookies.get('referral_id'), 'Accept': 'application/json' },
                                                maxFilesize: 0.5,
                                                autoProcessQueue: true,
                                                createImageThumbnails: true,
                                                init: function () {
                                                    this.on("error", function(file, errorMessage, xhr) {
                                                        console.error("Upload error:", errorMessage);
                                                        setUploadError(errorMessage || "An error occurred during upload");
                                                        if (xhr) {
                                                            console.error("XHR:", xhr);
                                                        }
                                                    });

                                                    this.on("addedfile", function(file) {
                                                        setUploadError(""); // Clear any previous errors
                                                        if (!file) {
                                                            setUploadError("No file provided");
                                                            return;
                                                        }
                                                    });

                                                    this.on("success", function () {
                                                        setUploadError(""); // Clear any errors on success
                                                        window.location.reload();
                                                    });
                                                }
                                            }} />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Grid>
        </MDBox>
    );
}

export default Image;
