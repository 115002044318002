// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { SvgIcon } from "@mui/material";

function HomeIcon(props) {
	if (props.icon == 'accepted') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
			</SvgIcon>
		);
	} else if (props.icon == 'minutes') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M12.4063 5.60022C7.62633 5.60022 3.73633 9.49022 3.73633 14.2702C3.73633 19.0502 7.62633 22.9502 12.4063 22.9502C17.1863 22.9502 21.0763 19.0602 21.0763 14.2802C21.0763 9.50022 17.1863 5.60022 12.4063 5.60022ZM13.1563 13.9502C13.1563 14.3602 12.8163 14.7002 12.4063 14.7002C11.9963 14.7002 11.6563 14.3602 11.6563 13.9502V8.95022C11.6563 8.54022 11.9963 8.20022 12.4063 8.20022C12.8163 8.20022 13.1563 8.54022 13.1563 8.95022V13.9502Z" fill="#2E3570" />
				<path d="M15.2959 4.4002H9.5159C9.1159 4.4002 8.7959 4.0802 8.7959 3.6802C8.7959 3.2802 9.1159 2.9502 9.5159 2.9502H15.2959C15.6959 2.9502 16.0159 3.2702 16.0159 3.6702C16.0159 4.0702 15.6959 4.4002 15.2959 4.4002Z" fill="#2E3570" />      </SvgIcon>
		);
	} else if (props.icon == 'prospect') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M5.40625 14.4502C3.19625 14.4502 1.40625 16.2402 1.40625 18.4502C1.40625 19.2002 1.61625 19.9102 1.98625 20.5102C2.67625 21.6702 3.94625 22.4502 5.40625 22.4502C6.86625 22.4502 8.13625 21.6702 8.82625 20.5102C9.19625 19.9102 9.40625 19.2002 9.40625 18.4502C9.40625 16.2402 7.61625 14.4502 5.40625 14.4502ZM7.37625 18.1202L5.24625 20.0902C5.10625 20.2202 4.91625 20.2902 4.73625 20.2902C4.54625 20.2902 4.35625 20.2202 4.20625 20.0702L3.21625 19.0802C2.92625 18.7902 2.92625 18.3102 3.21625 18.0202C3.50625 17.7302 3.98625 17.7302 4.27625 18.0202L4.75625 18.5002L6.35625 17.0202C6.65625 16.7402 7.13625 16.7602 7.41625 17.0602C7.69625 17.3602 7.67625 17.8402 7.37625 18.1202Z" fill="#604F6B" />
				<path d="M17.6562 3.38025H8.15625C5.30625 3.38025 3.40625 5.28025 3.40625 8.13025V12.5902C3.40625 12.9402 3.76625 13.1902 4.10625 13.1002C4.52625 13.0002 4.95625 12.9502 5.40625 12.9502C8.26625 12.9502 10.6262 15.2702 10.8862 18.0802C10.9062 18.3602 11.1362 18.5802 11.4062 18.5802H11.9563L16.1862 21.4002C16.8062 21.8202 17.6562 21.3602 17.6562 20.6002V18.5802C19.0762 18.5802 20.2663 18.1002 21.0963 17.2802C21.9263 16.4402 22.4062 15.2502 22.4062 13.8302V8.13025C22.4062 5.28025 20.5062 3.38025 17.6562 3.38025ZM16.2362 11.7602H9.57625C9.18625 11.7602 8.86625 11.4402 8.86625 11.0502C8.86625 10.6502 9.18625 10.3302 9.57625 10.3302H16.2362C16.6262 10.3302 16.9463 10.6502 16.9463 11.0502C16.9463 11.4402 16.6262 11.7602 16.2362 11.7602Z" fill="#604F6B" />				</SvgIcon>
		);
	} else if (props.icon == 'declined') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM15.7662 15.2502C16.0562 15.5402 16.0562 16.0202 15.7662 16.3102C15.6162 16.4602 15.4262 16.5302 15.2362 16.5302C15.0462 16.5302 14.8563 16.4602 14.7063 16.3102L12.4062 14.0102L10.1062 16.3102C9.95625 16.4602 9.76625 16.5302 9.57625 16.5302C9.38625 16.5302 9.19625 16.4602 9.04625 16.3102C8.75625 16.0202 8.75625 15.5402 9.04625 15.2502L11.3462 12.9502L9.04625 10.6502C8.75625 10.3602 8.75625 9.8802 9.04625 9.5902C9.33625 9.3002 9.81625 9.3002 10.1062 9.5902L12.4062 11.8902L14.7063 9.5902C14.9963 9.3002 15.4762 9.3002 15.7662 9.5902C16.0562 9.8802 16.0562 10.3602 15.7662 10.6502L13.4663 12.9502L15.7662 15.2502Z" fill="#6B4A5C" />
			</SvgIcon>
		);
	}
	else if (props.icon == 'overdue') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M19.9162 6.8002L13.9762 3.3702C13.0062 2.8102 11.8062 2.8102 10.8262 3.3702L4.89617 6.8002C3.92617 7.3602 3.32617 8.4002 3.32617 9.5302V16.3702C3.32617 17.4902 3.92617 18.5302 4.89617 19.1002L10.8362 22.5302C11.8062 23.0902 13.0062 23.0902 13.9862 22.5302L19.9262 19.1002C20.8962 18.5402 21.4962 17.5002 21.4962 16.3702V9.5302C21.4862 8.4002 20.8862 7.3702 19.9162 6.8002ZM11.6562 8.7002C11.6562 8.2902 11.9962 7.9502 12.4062 7.9502C12.8162 7.9502 13.1562 8.2902 13.1562 8.7002V13.9502C13.1562 14.3602 12.8162 14.7002 12.4062 14.7002C11.9962 14.7002 11.6562 14.3602 11.6562 13.9502V8.7002ZM13.3262 17.5802C13.2762 17.7002 13.2062 17.8102 13.1162 17.9102C12.9262 18.1002 12.6762 18.2002 12.4062 18.2002C12.2762 18.2002 12.1462 18.1702 12.0262 18.1202C11.8962 18.0702 11.7962 18.0002 11.6962 17.9102C11.6062 17.8102 11.5362 17.7002 11.4762 17.5802C11.4262 17.4602 11.4062 17.3302 11.4062 17.2002C11.4062 16.9402 11.5062 16.6802 11.6962 16.4902C11.7962 16.4002 11.8962 16.3302 12.0262 16.2802C12.3962 16.1202 12.8362 16.2102 13.1162 16.4902C13.2062 16.5902 13.2762 16.6902 13.3262 16.8202C13.3762 16.9402 13.4062 17.0702 13.4062 17.2002C13.4062 17.3302 13.3762 17.4602 13.3262 17.5802Z" fill="#6F331B" />
			</SvgIcon>
		);
	}
	else if (props.icon == 'sidesclosed') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M13.0843 20.9073C13.359 20.9712 13.3842 21.331 13.1166 21.4202V21.4202L11.5366 21.9402C7.56659 23.2202 5.47659 22.1502 4.18659 18.1802L2.90659 14.2302C1.62659 10.2602 2.68659 8.16024 6.65659 6.88024L7.18059 6.70671C7.58345 6.57329 7.97552 6.97735 7.86112 7.38602C7.80442 7.5886 7.74987 7.8 7.69659 8.02024L6.71659 12.2102C5.61659 16.9202 7.22659 19.5202 11.9366 20.6402L13.0843 20.9073Z" fill="#604F6B" />
				<path d="M17.5764 4.1602L15.9064 3.7702C12.5664 2.9802 10.5764 3.6302 9.40643 6.0502C9.10643 6.6602 8.86643 7.4002 8.66643 8.2502L7.68643 12.4402C6.70643 16.6202 7.99643 18.6802 12.1664 19.6702L13.8464 20.0702C14.4264 20.2102 14.9664 20.3002 15.4664 20.3402C18.5864 20.6402 20.2464 19.1802 21.0864 15.5702L22.0664 11.3902C23.0464 7.2102 21.7664 5.1402 17.5764 4.1602ZM15.6964 14.2802C15.6064 14.6202 15.3064 14.8402 14.9664 14.8402C14.9064 14.8402 14.8464 14.8302 14.7764 14.8202L11.8664 14.0802C11.4664 13.9802 11.2264 13.5702 11.3264 13.1702C11.4264 12.7702 11.8364 12.5302 12.2364 12.6302L15.1464 13.3702C15.5564 13.4702 15.7964 13.8802 15.6964 14.2802ZM18.6264 10.9002C18.5364 11.2402 18.2364 11.4602 17.8964 11.4602C17.8364 11.4602 17.7764 11.4502 17.7064 11.4402L12.8564 10.2102C12.4564 10.1102 12.2164 9.7002 12.3164 9.3002C12.4164 8.9002 12.8264 8.6602 13.2264 8.7602L18.0764 9.9902C18.4864 10.0802 18.7264 10.4902 18.6264 10.9002Z" fill="#604F6B" />
			</SvgIcon>
		);
	}
	else if (props.icon == 'creators_escrow') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM11.25 13.75H6.75C6.34 13.75 6 13.41 6 13C6 12.59 6.34 12.25 6.75 12.25H11.25C11.66 12.25 12 12.59 12 13C12 13.41 11.66 13.75 11.25 13.75ZM12 9.75H6C5.59 9.75 5.25 9.41 5.25 9C5.25 8.59 5.59 8.25 6 8.25H12C12.41 8.25 12.75 8.59 12.75 9C12.75 9.41 12.41 9.75 12 9.75Z" fill="#375525" />
				<path d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z" fill="#375525" />
			</SvgIcon>
		);
	}
	else if (props.icon == 'creators_pending_revenue') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M17.39 15.67L13.35 12H10.64L6.59998 15.67C5.46998 16.69 5.09998 18.26 5.64998 19.68C6.19998 21.09 7.53998 22 9.04998 22H14.94C16.46 22 17.79 21.09 18.34 19.68C18.89 18.26 18.52 16.69 17.39 15.67ZM13.82 18.14H10.18C9.79998 18.14 9.49998 17.83 9.49998 17.46C9.49998 17.09 9.80998 16.78 10.18 16.78H13.82C14.2 16.78 14.5 17.09 14.5 17.46C14.5 17.83 14.19 18.14 13.82 18.14Z" fill="#375525" />
				<path d="M18.35 4.32C17.8 2.91 16.46 2 14.95 2H9.05004C7.54004 2 6.20004 2.91 5.65004 4.32C5.11004 5.74 5.48004 7.31 6.61004 8.33L10.65 12H13.36L17.4 8.33C18.52 7.31 18.89 5.74 18.35 4.32ZM13.82 7.23H10.18C9.80004 7.23 9.50004 6.92 9.50004 6.55C9.50004 6.18 9.81004 5.87 10.18 5.87H13.82C14.2 5.87 14.5 6.18 14.5 6.55C14.5 6.92 14.19 7.23 13.82 7.23Z" fill="#375525" />
			</SvgIcon>
		);
	}
	else if (props.icon == 'creators_closed_revenue') {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M11.25 8.07996V10.94L10.24 10.59C9.72998 10.41 9.41998 10.24 9.41998 9.36996C9.41998 8.65996 9.94998 8.07996 10.6 8.07996H11.25Z" fill="#375525" />
				<path d="M14.58 14.6301C14.58 15.3401 14.05 15.9201 13.4 15.9201H12.75V13.0601L13.76 13.4101C14.27 13.5901 14.58 13.7601 14.58 14.6301Z" fill="#375525" />
				<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z" fill="#375525" />
			</SvgIcon>
		)
	}
	else {
		return (
			<SvgIcon {...props} sx={{ width: "1.25em", height: "1.25em" }}>
				<path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
			</SvgIcon>
		);
	}

}

function MiniStatisticsCard({ 
	bgColor = "white",
	title = {
		fontWeight: "light",
		text: "",
	},
	percentage = {
		color: "success",
	},
	direction = "right",
	count, 
	icon 
}) {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	return (
		<Card sx={{ overflow: "hidden" }}>
			<MDBox
				bgColor={bgColor}
				variant="gradient"
				sx={({ palette: { background } }) => ({
					background: darkMode && background.card,
				})}
			>
				<MDBox p={2}>
					<Grid container alignItems="center">
						{direction === "left" ? (
							<Grid item xs={4}>
								<MDBox
									variant="gradient"
									bgColor={bgColor === "white" ? icon.color : "white"}
									color={bgColor === "white" ? "white" : "dark"}
									width="4rem"
									height="4rem"
									borderRadius="md"
									display="flex"
									justifyContent="center"
									alignItems="center"
									shadow="md"
								>
									<HomeIcon />
								</MDBox>
							</Grid>
						) : null}
						<Grid item xs={8}>
							<MDBox
								ml={direction === "left" ? 2 : 0}
								lineHeight={1}
								textAlign={direction === "left" ? "right" : "left"}
							>
								<MDTypography
									variant="overline"
									opacity={bgColor === "white" ? 1 : 0.7}
									textTransform="capitalize"
									fontWeight={title.fontWeight}
									color="solidtext"
									sx={{ fontSize: "0.75rem", fontWeight: "500", lineHeight: "1.4" }}
								>
									{title.text}
								</MDTypography>
								<MDTypography
									variant="h3"
									color="soliddark"
								>
									{count.text}{" "}
									<MDTypography variant="button" color={percentage.color} fontWeight="bold">
										{percentage.text != "" ? percentage.text : ""}
									</MDTypography>
									<MDTypography variant="button" color={percentage.othertextcolor} ml={1}>
										{percentage.othertext}
									</MDTypography>
								</MDTypography>
							</MDBox>
						</Grid>
						{direction === "right" ? (
							<Grid item xs={4}>
								<MDBox
									variant="gradient"
									bgColor={bgColor === "white" ? icon.color : "white"}
									color={bgColor === "white" ? "white" : "dark"}
									width="4rem"
									height="4rem"
									marginLeft="auto"
									borderRadius="xl"
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<HomeIcon icon={icon.component} />
								</MDBox>
							</Grid>
						) : null}
					</Grid>
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
	bgColor: PropTypes.oneOf([
		"white",
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"dark",
		'gradientgreen',
		'gradientIris',
		"gradientViolet",
		"gradientRose",
		"gradientPeach",
		"linear-gradient(180deg, #CFD3F9 0%, #B8BEF7 100%)"
	]),
	title: PropTypes.PropTypes.shape({
		fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
		text: PropTypes.string,
	}),
	count: PropTypes.shape({
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	percentage: PropTypes.shape({
		color: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"dark",
			"white",
			"solidsuccess",
			"soliderror",
		]),
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		othertext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		othertextcolor: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"dark",
			"white",
			"solidsuccess",
			"soliderror",
			"solidtext"
		]),
	}),
	icon: PropTypes.shape({
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "linear-gradient(180deg, #CFD3F9 0%, #B8BEF7 100%)", 'gradientgreen',
			'gradientIris',
			"gradientViolet",
			"gradientRose",
			"gradientPeach",]),
		component: PropTypes.node.isRequired,
	}).isRequired,
	direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
