import { useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Title from "components/global/title";
import OverviewCard from "./overviewCard";
import Pricing from "./pricing";
import { useSelector } from "react-redux";
import default_data from "values/default";
import Cookies from "js-cookie";

function Overview() {

  const agent = useSelector((state) => state.data.agent) || {};

  useEffect(() => {
    Cookies.set("hideBanner", "true", 30);
  }, []);

  const plans = [
    {
      planName: default_data.subscription_plans.free_profile,
      price: '$0',
      period: '/month',
      referralFee: '40%',
      features: [
        { text: 'Eligible for new referrals', enabled: true },
        { text: 'Submit your own referrals', enabled: false },
        { text: 'Priority for buyer referrals', enabled: false },
        { text: 'Priority for seller referrals', enabled: false },
        { text: 'Home & Money profile', enabled: false },
        { text: 'Targeted Lead Generation Campaigns', enabled: false },
        { text: 'Direct-to-Agent lead flow', enabled: false },
        { text: 'Home & Money testimonials ', enabled: false },
        //{ text: 'Custom reporting dashboard  ', enabled: false },
      ]
    },
    {
      planName: default_data.subscription_plans.basic,
      price: '$59',
      period: '/month',
      referralFee: '35%',
      features: [
        { text: 'Eligible for new referrals', enabled: true },
        { text: 'Submit your own referrals', enabled: true },
        { text: 'Priority for buyer referrals', enabled: false },
        { text: 'Priority for seller referrals', enabled: false },
        { text: 'Home & Money profile', enabled: false },
        { text: 'Targeted Lead Generation Campaigns', enabled: false },
        { text: 'Direct-to-Agent lead flow', enabled: false },
        { text: 'Home & Money testimonials ', enabled: false },
        //{ text: 'Custom reporting dashboard  ', enabled: false },
      ]
    },
    {
      planName: default_data.subscription_plans.pro_agent,
      price: '$99',
      period: '/month',
      referralFee: '33%',
      features: [
        { text: 'Eligible for new referrals', enabled: true },
        { text: 'Submit your own referrals', enabled: true },
        { text: 'Priority for buyer referrals', enabled: true, tooltip: 2 },
        { text: 'Priority for seller referrals', enabled: true, tooltip: 3 },
        { text: 'Home & Money profile', enabled: false },
        { text: 'Targeted Lead Generation Campaigns', enabled: false },
        { text: 'Direct-to-Agent lead flow', enabled: false },
        { text: 'Home & Money testimonials ', enabled: false },
        //{ text: 'Custom reporting dashboard  ', enabled: false },
      ]
    },
    {
      planName: default_data.subscription_plans.expert,
      price: '$299',
      period: '/month*',
      referralFee: '30%',
      features: [
        { text: 'Eligible for new referrals', enabled: true },
        { text: 'Submit your own referrals', enabled: true },
        { text: 'Priority for buyer referrals', enabled: true, tooltip: 2 },
        { text: 'Priority for seller referrals', enabled: true, tooltip: 3 },
        { text: 'Home & Money profile', enabled: true, tooltip: 4 },
        { text: 'Targeted Lead Generation Campaigns', enabled: true, tooltip: 5 },
        { text: 'Direct-to-Agent lead flow', enabled: true, tooltip: 6 },
        { text: 'Home & Money testimonials ', enabled: true, tooltip: 7 },
        //{ text: 'Custom reporting dashboard  ', enabled: true, tooltip: 8 },

      ],
    },
  ];

  const activePlan = agent.subscription_plan;
  const activePlanStatus = agent.subscription_status;
  const activePrice = agent.subscription_price || 0;
  const maxioSubcriptionID = agent.maxio_subscription_id;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="flex flex-col items-start gap-[32px] self-stretch pb-12 max-w-[1200px] ml-auto mr-auto">
        <div className="flex items-start self-stretch">
          <Title
            text="My Plan"
            className="text-[28px] sm:text-[30px]"
          />
        </div>
        <div className="flex flex-col items-start gap-[40px] self-stretch">
          <div className="flex flex-col items-start gap-[24px] self-stretch">
            <OverviewCard
              activePlan={activePlan}
              activePlanStatus={activePlanStatus}
              activePrice={activePrice}
              maxioSubcriptionID={maxioSubcriptionID}
            />
            <Pricing
              plans={plans}
              activePlan={activePlan}
              activePrice={activePrice}
              activeReferralFee={plans.find((plan) => plan.planName === activePlan)?.referralFee}
              activePlanStatus={activePlanStatus}
            />
          </div>
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
