const link = {
  styleOverrides: {
    root: {
      textDecoration: "none",
      color: "inherit",
    },
  },
};

export default link;
