// Material Dashboard 2 PRO React layouts
import ProfileOverview from "layouts/pages/profile/profile-overview";
import SignInBasic from "layouts/authentication/sign-in/basic";

import ContactUs from "layouts/contactus";
import BrandToolKit from "layouts/brandtoolkit";

// @mui icons
import Icon from "@mui/material/Icon";


const salesSplitRoutes = [
    {
        name: "user_data",
        type: "collapse",
        key: "user_data",
        collapse: [
            {
                name: "Edit Profile",
                key: "profile",
                route: "/profile",
                component: <ProfileOverview />,
            },
            {
                name: "Logout",
                key: "logout",
                route: "",
                component: <SignInBasic />,
            },
        ],
    },
    { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="medium">home</Icon>,
        route:"/dashboard",
        noCollapse: true,
    },
    { type: "divider", key: "divider-1" },
    { type: "title", title: "Resources", key: "resources" },
    
    {
        type: "collapse",
        name: "Brand Toolkit",
        key: "BrandToolKit",
        icon: <Icon fontSize="medium">contactus</Icon>,
        route:"/brandtoolkit",
        component: <BrandToolKit />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Tips & Guides",
        key: "TipsAndGuides",
        icon: <Icon fontSize="medium">active</Icon>,
        collapse: [
            { name: "Portal Walkthrough", key: "Portal Walkthrough", route: "/portal/walkthrough" },
        ],
    },
    {
        type: "collapse",
        name: "Contact Us",
        key: "contactus",
        icon: <Icon fontSize="medium">contactus</Icon>,
        route:"/contactus",
        component: <ContactUs />,
        noCollapse: true,
    },

];

export default salesSplitRoutes;
