import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import { SvgIcon } from "@mui/material";

function HomeIcon(props) {
  if (props.icon == 'newUsers') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
        <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" fill="#2E3570" />
        <path d="M17.08 14.15C14.29 12.29 9.73996 12.29 6.92996 14.15C5.65996 15 4.95996 16.15 4.95996 17.38C4.95996 18.61 5.65996 19.75 6.91996 20.59C8.31996 21.53 10.16 22 12 22C13.84 22 15.68 21.53 17.08 20.59C18.34 19.74 19.04 18.6 19.04 17.36C19.03 16.13 18.34 14.99 17.08 14.15Z" fill="#2E3570" />			</SvgIcon>
    );
  } else if (props.icon == 'sessions') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path d="M12 4.65002C7.21996 4.65002 3.32996 8.54002 3.32996 13.32C3.32996 18.1 7.21996 22 12 22C16.78 22 20.67 18.11 20.67 13.33C20.67 8.55002 16.78 4.65002 12 4.65002ZM12.75 13C12.75 13.41 12.41 13.75 12 13.75C11.59 13.75 11.25 13.41 11.25 13V8.00002C11.25 7.59002 11.59 7.25002 12 7.25002C12.41 7.25002 12.75 7.59002 12.75 8.00002V13Z" fill="#6B4A5C"/>
<path d="M14.89 3.45H9.11001C8.71001 3.45 8.39001 3.13 8.39001 2.73C8.39001 2.33 8.71001 2 9.11001 2H14.89C15.29 2 15.61 2.32 15.61 2.72C15.61 3.12 15.29 3.45 14.89 3.45Z" fill="#6B4A5C"/>
        </SvgIcon>
    );
  } else if (props.icon == 'totalReferrals') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path fillRule="evenodd" clipRule="evenodd" d="M19 1C20.18 1 21.25 1.51 21.97 2.33C22.61 3.04 23 3.98 23 5C23 5.32 22.96 5.63 22.88 5.93C22.79 6.33 22.63 6.72 22.42 7.06C21.94 7.87 21.17 8.5 20.25 8.79C19.86 8.93 19.44 9 19 9C17.97 9 17.04 8.61 16.34 7.97C16.04 7.71 15.78 7.4 15.58 7.06C15.21 6.46 15 5.75 15 5C15 4.56 15.07 4.14 15.2 3.74C15.39 3.16 15.71 2.64 16.13 2.21C16.86 1.46 17.88 1 19 1ZM18.3663 6.88012C18.5263 6.88012 18.6863 6.82012 18.8063 6.70012L21.3263 4.18012C21.5763 3.94012 21.5763 3.54012 21.3263 3.30012C21.0863 3.06012 20.6863 3.06012 20.4463 3.30012L18.3663 5.38012L17.5463 4.56012C17.3063 4.32012 16.9063 4.32012 16.6663 4.56012C16.4263 4.80012 16.4263 5.20012 16.6663 5.44012L17.9263 6.70012C18.0463 6.81012 18.2063 6.88012 18.3663 6.88012Z" fill="#375525"/>
<path d="M22 12C22 10.69 21.75 9.43 21.28 8.28C20.97 8.5 20.62 8.67 20.25 8.79C20.14 8.83 20.03 8.86 19.91 8.89C20.29 9.85 20.5 10.9 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C13.09 3.5 14.14 3.71 15.1 4.09C15.13 3.97 15.16 3.86 15.2 3.74C15.32 3.37 15.49 3.03 15.72 2.72C14.57 2.25 13.31 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12Z" fill="#375525"/>
<path d="M12 6.93005C9.93 6.93005 8.25 8.61005 8.25 10.6801C8.25 12.7101 9.84 14.3601 11.95 14.4201C11.98 14.4201 12.02 14.4201 12.04 14.4201C12.06 14.4201 12.09 14.4201 12.11 14.4201C12.12 14.4201 12.13 14.4201 12.13 14.4201C14.15 14.3501 15.74 12.7101 15.75 10.6801C15.75 8.61005 14.07 6.93005 12 6.93005Z" fill="#375525"/>        </SvgIcon>
    );
  } else if (props.icon == 'qualifiedLeads') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path d="M15.3899 5.21003L16.7999 8.03003C16.9899 8.42003 17.4999 8.79003 17.9299 8.87003L20.4799 9.29003C22.1099 9.56003 22.4899 10.74 21.3199 11.92L19.3299 13.91C18.9999 14.24 18.8099 14.89 18.9199 15.36L19.4899 17.82C19.9399 19.76 18.8999 20.52 17.1899 19.5L14.7999 18.08C14.3699 17.82 13.6499 17.82 13.2199 18.08L10.8299 19.5C9.11994 20.51 8.07994 19.76 8.52994 17.82L9.09994 15.36C9.18994 14.88 8.99994 14.23 8.66994 13.9L6.67994 11.91C5.50994 10.74 5.88994 9.56003 7.51994 9.28003L10.0699 8.86003C10.4999 8.79003 11.0099 8.41003 11.1999 8.02003L12.6099 5.20003C13.3799 3.68003 14.6199 3.68003 15.3899 5.21003Z" fill="#2E3570"/>
<path d="M8 5.75H2C1.59 5.75 1.25 5.41 1.25 5C1.25 4.59 1.59 4.25 2 4.25H8C8.41 4.25 8.75 4.59 8.75 5C8.75 5.41 8.41 5.75 8 5.75Z" fill="#2E3570"/>
<path d="M5 19.75H2C1.59 19.75 1.25 19.41 1.25 19C1.25 18.59 1.59 18.25 2 18.25H5C5.41 18.25 5.75 18.59 5.75 19C5.75 19.41 5.41 19.75 5 19.75Z" fill="#2E3570"/>
<path d="M3 12.75H2C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H3C3.41 11.25 3.75 11.59 3.75 12C3.75 12.41 3.41 12.75 3 12.75Z" fill="#2E3570"/>      </SvgIcon>
    );
  }
  else if (props.icon == 'pageViews') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path d="M21.25 9.15005C18.94 5.52005 15.56 3.43005 12 3.43005C10.22 3.43005 8.49 3.95005 6.91 4.92005C5.33 5.90005 3.91 7.33005 2.75 9.15005C1.75 10.7201 1.75 13.2701 2.75 14.8401C5.06 18.4801 8.44 20.5601 12 20.5601C13.78 20.5601 15.51 20.0401 17.09 19.0701C18.67 18.0901 20.09 16.6601 21.25 14.8401C22.25 13.2801 22.25 10.7201 21.25 9.15005ZM12 16.0401C9.76 16.0401 7.96 14.2301 7.96 12.0001C7.96 9.77005 9.76 7.96005 12 7.96005C14.24 7.96005 16.04 9.77005 16.04 12.0001C16.04 14.2301 14.24 16.0401 12 16.0401Z" fill="#604F6B"/>
<path d="M12 9.14001C10.43 9.14001 9.15002 10.42 9.15002 12C9.15002 13.57 10.43 14.85 12 14.85C13.57 14.85 14.86 13.57 14.86 12C14.86 10.43 13.57 9.14001 12 9.14001Z" fill="#604F6B"/>
      </SvgIcon>
    );
  }
  else if (props.icon == 'leadRevenue') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path d="M11.25 8.07996V10.94L10.24 10.59C9.72998 10.41 9.41998 10.24 9.41998 9.36996C9.41998 8.65996 9.94998 8.07996 10.6 8.07996H11.25Z" fill="#375525"/>
<path d="M14.58 14.6301C14.58 15.3401 14.05 15.9201 13.4 15.9201H12.75V13.0601L13.76 13.4101C14.27 13.5901 14.58 13.7601 14.58 14.6301Z" fill="#375525"/>
<path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z" fill="#375525"/>      </SvgIcon>
    );
  }
  else if (props.icon == 'leadsGenerated') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
<path d="M16.88 10C14.26 10 12.13 12.13 12.13 14.75C12.13 15.64 12.38 16.48 12.82 17.2C13.64 18.58 15.15 19.5 16.88 19.5C18.61 19.5 20.12 18.57 20.94 17.2C21.38 16.49 21.63 15.64 21.63 14.75C21.63 12.13 19.51 10 16.88 10ZM18.66 15.48H17.63V16.55C17.63 16.96 17.29 17.3 16.88 17.3C16.47 17.3 16.13 16.96 16.13 16.55V15.48H15.11C14.7 15.48 14.36 15.14 14.36 14.73C14.36 14.32 14.7 13.98 15.11 13.98H16.13V13C16.13 12.59 16.47 12.25 16.88 12.25C17.29 12.25 17.63 12.59 17.63 13V13.98H18.66C19.07 13.98 19.41 14.32 19.41 14.73C19.41 15.14 19.07 15.48 18.66 15.48Z" fill="#6F331B"/>
<path d="M20.58 4.02V6.24C20.58 7.05 20.08 8.06 19.58 8.57L19.4 8.73C19.26 8.86 19.05 8.89 18.87 8.83C18.67 8.76 18.47 8.71 18.27 8.66C17.83 8.55 17.36 8.5 16.88 8.5C13.43 8.5 10.63 11.3 10.63 14.75C10.63 15.89 10.94 17.01 11.53 17.97C12.03 18.81 12.73 19.51 13.49 19.98C13.72 20.13 13.81 20.45 13.61 20.63C13.54 20.69 13.47 20.74 13.4 20.79L12 21.7C10.7 22.51 8.91004 21.6 8.91004 19.98V14.63C8.91004 13.92 8.51004 13.01 8.11004 12.51L4.32004 8.47C3.82004 7.96 3.42004 7.05 3.42004 6.45V4.12C3.42004 2.91 4.32004 2 5.41004 2H18.59C19.68 2 20.58 2.91 20.58 4.02Z" fill="#6F331B"/>      </SvgIcon>
    );
  }
  else if (props.icon == 'creators_escrow') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
        <path d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM11.25 13.75H6.75C6.34 13.75 6 13.41 6 13C6 12.59 6.34 12.25 6.75 12.25H11.25C11.66 12.25 12 12.59 12 13C12 13.41 11.66 13.75 11.25 13.75ZM12 9.75H6C5.59 9.75 5.25 9.41 5.25 9C5.25 8.59 5.59 8.25 6 8.25H12C12.41 8.25 12.75 8.59 12.75 9C12.75 9.41 12.41 9.75 12 9.75Z" fill="#375525" />
        <path d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z" fill="#375525" />
      </SvgIcon>
    );
  }
  else if (props.icon == 'creators_pending_revenue') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
        <path d="M17.39 15.67L13.35 12H10.64L6.59998 15.67C5.46998 16.69 5.09998 18.26 5.64998 19.68C6.19998 21.09 7.53998 22 9.04998 22H14.94C16.46 22 17.79 21.09 18.34 19.68C18.89 18.26 18.52 16.69 17.39 15.67ZM13.82 18.14H10.18C9.79998 18.14 9.49998 17.83 9.49998 17.46C9.49998 17.09 9.80998 16.78 10.18 16.78H13.82C14.2 16.78 14.5 17.09 14.5 17.46C14.5 17.83 14.19 18.14 13.82 18.14Z" fill="#375525" />
        <path d="M18.35 4.32C17.8 2.91 16.46 2 14.95 2H9.05004C7.54004 2 6.20004 2.91 5.65004 4.32C5.11004 5.74 5.48004 7.31 6.61004 8.33L10.65 12H13.36L17.4 8.33C18.52 7.31 18.89 5.74 18.35 4.32ZM13.82 7.23H10.18C9.80004 7.23 9.50004 6.92 9.50004 6.55C9.50004 6.18 9.81004 5.87 10.18 5.87H13.82C14.2 5.87 14.5 6.18 14.5 6.55C14.5 6.92 14.19 7.23 13.82 7.23Z" fill="#375525" />
      </SvgIcon>
    );
  }
  else if (props.icon == 'creators_closed_revenue') {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
        <path d="M11.25 8.07996V10.94L10.24 10.59C9.72998 10.41 9.41998 10.24 9.41998 9.36996C9.41998 8.65996 9.94998 8.07996 10.6 8.07996H11.25Z" fill="#375525" />
        <path d="M14.58 14.6301C14.58 15.3401 14.05 15.9201 13.4 15.9201H12.75V13.0601L13.76 13.4101C14.27 13.5901 14.58 13.7601 14.58 14.6301Z" fill="#375525" />
        <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z" fill="#375525" />
      </SvgIcon>
    )
  }
  else {
    return (
      <SvgIcon {...props} sx={{ width: "1em", height: "1em" }}>
        <path d="M16.5963 2.9502H8.21625C4.57625 2.9502 2.40625 5.1202 2.40625 8.7602V17.1302C2.40625 20.7802 4.57625 22.9502 8.21625 22.9502H16.5863C20.2263 22.9502 22.3962 20.7802 22.3962 17.1402V8.7602C22.4062 5.1202 20.2363 2.9502 16.5963 2.9502ZM17.1863 10.6502L11.5162 16.3202C11.3762 16.4602 11.1862 16.5402 10.9862 16.5402C10.7862 16.5402 10.5963 16.4602 10.4563 16.3202L7.62625 13.4902C7.33625 13.2002 7.33625 12.7202 7.62625 12.4302C7.91625 12.1402 8.39625 12.1402 8.68625 12.4302L10.9862 14.7302L16.1263 9.5902C16.4163 9.3002 16.8963 9.3002 17.1863 9.5902C17.4763 9.8802 17.4763 10.3502 17.1863 10.6502Z" fill="#375525" />
      </SvgIcon>
    );
  }

}

function DefaultLineChart({ 
  icon = { color: "info", component: "" },
  title = "",
  description = "",
  height = "19.125rem",
  chart 
}) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0,
        pointRadius: 3,
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        pointBackgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium" color="inherit">
                <HomeIcon icon={icon.component} />
              </Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Line data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card sx={{borderRadius :  "0 0 12px 12px"}}>{renderChart}</Card> : renderChart;
}

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "gradientPeach",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
