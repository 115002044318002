import { useState, useEffect } from "react";
import * as React from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// User Components
import Company from "layouts/user_profile/components/company";
import User from "layouts/user_profile/components/user";
import Login from "layouts/user_profile/components/login";
import Image from "./components/Image";

import AuthService from "services/auth.service";
import Cookies from "js-cookie";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Userprofile({ stickyNavbar = false }) {
  const [loginDetails, setLoginDetails] = useState({});
  const [companyLogo, setCompanyLogo] = useState('');
  const [profile_image, setProfileImage] = useState('');
  const [influencerType, setInfluencerType] = useState('');

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setTabValue(parseInt(Cookies.get("tabValue") ? Cookies.get("tabValue") : 0));
    AuthService.getUserInfo().then((response) => {
      let data = response.data.response.agent;

      if (data.is_influencer && data.is_sales_split) {
        setInfluencerType('sales_split')
      } else if (data.is_influencer && !data.is_sales_split) {
        setInfluencerType('cost_per_lead');
      }
      setCompanyLogo(data.logo);
      setProfileImage(data.profile_image);

      makeLoginDetails(data);
    })
  }, [])

  const makeLoginDetails = (data) => {
    let logindata = {};
    logindata.email = data.email;
    setLoginDetails(logindata);
  }

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event, newValue) => {
    Cookies.set("tabValue", newValue, { path: '/profile' });
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <DashboardLayout>
      <DashboardNavbar absolute={!stickyNavbar} />
      {influencerType === "cost_per_lead" ?
        <MDBox mt={stickyNavbar ? 3 : 6}>
          <Grid container>
            <Grid item xs={12} sm={8} lg={4}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Login" {...a11yProps(1)} />
                  <Tab label="Logo" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={tabValue} index={0}>
            <User />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Login userdata={loginDetails} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Image logo={companyLogo} image={profile_image} influencer={influencerType} />
          </TabPanel>
        </MDBox>
        :
        <MDBox mt={stickyNavbar ? 3 : 6}>
          <Grid container>
            <Grid item xs={12} sm={8} lg={4}>
              <AppBar position="static" elevation={0} color="transparent">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab label="Brokerage" {...a11yProps(0)} />
                  <Tab label="Profile" {...a11yProps(1)} />
                  <Tab label="Login" {...a11yProps(2)} />
                  <Tab label="Logo" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={tabValue} index={0}>
            <Company />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <User />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Login userdata={loginDetails} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Image logo={companyLogo} image={profile_image} />
          </TabPanel>
        </MDBox>}
      <Footer />
    </DashboardLayout>
  );
}

Userprofile.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node,
};

export default Userprofile;
