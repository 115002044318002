/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";


import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

function CoverLayout({ 
  coverHeight = "40vh",
  image, 
  children, 
  maxWidth,
  logoWidth, 
  logoHeight 
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageLayout>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={10}
        sx={{
          background: `linear-gradient(256.89deg, #FFB1DA -16.72%, #434CA0 66.39%, #212650 104.8%);`
        }}>
        <Grid container justifyContent="center" pt={isMobile ? 0:4}>
          <Grid item>
            <Box
              align="center"
              component="img"
              sx={{
                width: logoWidth ? logoWidth:216,
                height: logoHeight ? logoHeight : 51,
                flex: `none`,
                order: 0,
              }}
              src={image}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" sx={{maxWidth : maxWidth}} mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item  sx={{maxWidth : "600px !important"}}xs={11} sm={9} md={8} lg={8} xl={8}>
            {children}
          </Grid>
        </Grid>
      </MDBox>

    </PageLayout>
  );
}

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
